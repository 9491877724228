.picture-shadow-container {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.picture-shadow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 200%;
  background-color: rgba(0, 0, 0, 0.1);
}

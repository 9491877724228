.authForm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.authForm form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.authForm input {
  width: 200px;
  margin-left: 5px;
  line-height: 25px;
  margin-bottom: 15px;
}

.authForm .formActions {
  margin-top: 15px;
  align-self: center;
  display: flex;
  justify-content: space-around;
}

.authForm .formActions button {
  cursor: pointer;
  height: 44px;
  border-radius: 20%;
  border: 0;
  padding: 5px 10px;
}

.authForm form a {
  align-self: center;
}

.login-options-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  color: #3f3f54;
}

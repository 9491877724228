.loader-overlay {
  position: fixed;
  top: -100vh;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.loader-overlay--visible {
  top: 0px;
}

.loader-overlay .loader-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.loader-overlay span {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #fff;
  margin-right: 5px;
  animation: blink 1s infinite;
}

.loader-overlay span:nth-child(2) {
  animation-delay: 0.2s;
}

.loader-overlay span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

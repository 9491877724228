.proposal {
  display: grid;
  grid-template-areas:
    "picture title"
    "picture releaseDate"
    "picture direction";
  grid-template-columns: 100px 1fr;
  grid-column-gap: 5px;
  margin-bottom: 10px;
}

.proposal img {
  grid-area: picture;
}

.proposal__title {
  grid-area: title;
}

.proposal__releaseDate {
  grid-area: releaseDate;
}

.proposal__direction {
  grid-area: direction;
}

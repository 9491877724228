.movie-list {
  display: grid;
  grid-row-gap: 10px;
}

@media all and (min-width: 500px) {
  .movie-list {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
    grid-template-rows: initial;
  }
}

@media all and (min-width: 980px) {
  .movie-list {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

.flash-message-container {
  position: fixed;
  top: 0px;
  transform: translate3d(0, -100%, 0);
  z-index: 10;
  width: 100vw;
  animation: slide-in 0.4s linear forwards, slide-out 0.4s 1s linear forwards;
  text-align: center;
  color: #000;
}

.flash-message-container[status="success"] {
  color: #fff;
  font-weight: 600;
  background-color: #aed69b;
}

.flash-message-container[status="failure"] {
  background-color: #e3665a;
}

.flash-message-container p {
  margin: 5px 10px;
}

@keyframes slide-in {
  from {
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0%, 0);
  }
}

@keyframes slide-out {
  from {
    transform: translate3d(0, 0%, 0);
  }

  to {
    transform: translate3d(0, -100%, 0);
  }
}

.home-container {
  display: grid;
  grid-template-areas:
    "toolbox"
    "previous-btn"
    "movies"
    "next-btn";
  max-width: 100vw;
  overflow: hidden;
  padding-top: 45px;
}

.home-container .toolbox {
  display: grid;
  grid-area: toolbox;
  grid-template-areas: "search count add" "search count add";
  grid-template-columns: auto minmax(auto, 120px) 40px;
  grid-template-rows: 40px 25px;
  padding: 4px;
}

.home-container .movie-count {
  grid-area: count;
  padding: 5px 10px;
  line-height: 30px;
  display: inline-block;
  color: #000;
  text-align: right;
  font-size: 12px;
}

.home-container .add-movie-btn {
  grid-area: add;
  position: fixed;
  right: 0px;
  width: 40px;
  height: 40px;
  text-align: center;
  z-index: 2;
  color: #fff;
  line-height: 40px;
  background-color: #59cd90;
  text-decoration: none;
}

.home-container .previous-btn {
  grid-area: previous-btn;
  height: 30px;
  margin-bottom: 10px;
}
.home-container .movie-list {
  grid-area: movies;
  position: relative;
  z-index: 0;
}

.home-container .next-btn {
  grid-area: next-btn;
  margin-top: 10px;
  height: 30px;
}

.home-container .fetch-more {
  grid-area: more;
  margin-top: 15px;
  height: 40px;
}

@media screen and (max-width: 500px) {
  .home-container {
    display: flex;
    flex-direction: column;
    padding: 0px 20px 40px 20px;
  }


  .home-container .add-movie-btn {
    position: fixed;
    border-radius: 40px;
    bottom: 10px;
    right: 10px;
  }
}

.searchbox input {
    display: block;
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0,0,0,.4);
    -webkit-appearance: textfield;
}

.searchbox span {
    font-weight: bold;
    padding: 2px 4px;
}